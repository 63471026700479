






















































import { Component, Vue } from 'vue-property-decorator'
import SettingService from '@/services/SettingService'
import { ILanguage } from '@/models/Setting/Interface'
import { getModule } from 'vuex-module-decorators'
import Setting from '@/store/modules/Setting'
import store from '@/store'
import i18n from '@/lang'

const SettingModule = getModule(Setting, store)

@Component
export default class SettingLanguage extends Vue {
  private list_languages: ILanguage[] = []
  private select_language_id: number =
    store.state.setting.setting.setting_language.id
  private confirmChange: any

  created() {
    this.getLanguages()
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (
      this.select_language_id !==
      store.state.setting.setting.setting_language.id
    ) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  reloadPage() {
    window.location.reload()
  }

  getLanguages() {
    let langs = [
      {
        id: 0,
        name: this.$t('lang.choose_your_language'),
        code: 'ja'
      },
      {
        id: 1,
        name: this.$t('lang.ja'),
        code: 'ja'
      },
      {
        id: 2,
        name: this.$t('lang.en'),
        code: 'en'
      }
    ]
    this.list_languages = langs
  }

  async changeLanguage() {
    let selected_language = this.list_languages.find(
      item => item.id === this.select_language_id
    )
    let setting_data = Object.assign({}, store.state.setting.setting)
    setting_data.setting_language = selected_language
    await SettingService.updateUserSetting(setting_data)
      .then(response => {
        if (response.status === 200) {
          let setting_language = response.data.setting_language
          i18n.locale = setting_language.code
          i18n.locale === 'en'
            ? (setting_language.name = this.$t('lang.en'))
            : (setting_language.name = this.$t('lang.ja'))
          SettingModule.CHANGE_LANGUAGE(setting_language)
          this.$bvModal.show('modal-success')
        }
      })
      .catch(error => {
        this.select_language_id =
          store.state.setting.setting.setting_language.id
        this.$bvModal.show('modal-error')
      })
    this.getLanguages()
  }
}
